@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 1rem;
	font-family:
		"Inter",
		-apple-system,
		BlinkMacSystemFont,
		"Segoe UI",
		"Roboto",
		"Oxygen",
		"Ubuntu",
		"Cantarell",
		"Fira Sans",
		"Droid Sans",
		"Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #292929;
}

body {
	@apply bg-salt;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
* {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}
